import sheetQuestionService from "../http/sheet.question.service";
import mqtt from "mqtt";
import { ref } from "vue";

export function useMqtt() {
  let questions = ref([]);
  let startBoard = ref(false);
  let startGame = ref(false);
  let showPatientModal = ref(false);
  let questionAnswer = ref(null);
  let gameStars = ref(0);
  let mqttTopic = ref("");

  let gameLink = ref("");

  // let client = null
  let screenSize = ref(false);
  let shareScreen = ref(false);
  let videoLocation = ref(false);
  let camera = ref(true);
  let microphone = ref(true);

  let remoteShareScreen = ref(false);
  let remoteVideoLocation = ref(false);
  let remoteScreenSize = ref(false);

  let connection = {
    host: "konusmaterapim.com",
    port: 8084,
    endpoint: "/mqtt",
    clean: true, // Reserved session
    connectTimeout: 4000, // Time out
    reconnectPeriod: 4000, // Reconnection interval
    // Certification Information
  };
  let subscription = {
    topic: "topic/mqttx",
    qos: 0,
  };
  let publish = {
    topic: "topic/mqttx",
    qos: 0,
    payload: '{ "msg": "Hello, I am memedov." }',
  };

  const doPublish = (client, message) => {
    console.log(client, message, mqttTopic.value);
    const { qos } = publish;
    client.publish(mqttTopic.value, message, qos, (error) => {
      if (error) {
        console.log("Publish error", error);
      }
    });
  };

  const doSubscribe = (client) => {
    const { qos } = subscription;
    client.subscribe(mqttTopic.value, { qos }, (error, res) => {
      if (error) {
        console.log("Subscribe to topics error", error);
        return;
      }
      console.log("Subscribe to topics res", res);
    });
  };

  const createConnection = (client, user, appointment) => {
    const { host, port, endpoint } = connection;
    const connectUrl = `wss://${host}:${port}${endpoint}`;
    console.log(appointment.id);
    mqttTopic.value = appointment.id;

    try {
      client = mqtt.connect(connectUrl, {
        clientId: user.user_login,
        username: `${user.first_name}  "" ${user.last_name}`,
      });
      client.on("connect", () => {
        console.log("Connection succeeded!");
      });
      client.on("error", (error) => {
        console.log("Connection failed", error);
      });
      client.on("message", (topic, message) => {
        mqttMessageController(message, user);
      });

      doSubscribe(client);

      return client;
    } catch (error) {
      console.log("mqtt.connect error", error);
    }
  };

  const mqttMessageController = (message, user) => {
    const comingJson = JSON.parse(message);
    console.log(comingJson);
    if (message.includes("questionId")) {
      sheetQuestionService.getById(comingJson.questionId).then((data) => {
        questions.value.push(...data);
        console.log(questions);
        showPatientModal.value = true;
      });
    } else if (message.includes("isCustom")) {
      const data = {
        appointment_id: comingJson.appointment_id,
        is_true: comingJson.is_true,
        patient_id: comingJson.patient_id,
        word: comingJson.word,
      };
      questions.value.push(data);
      showPatientModal.value = true;
    } else if (message.includes("resetQuestions")) {
      questions.value = [];
    }
    if (user.role == "patient") {
      if (message.includes("gameLink")) {
        gameLink.value = comingJson.gameLink;
        document.getElementById("gameFrame").src =
          gameLink.value + "?dktdatetime=" + Date.now();
      } else if (message.includes("TwoPlayerGame")) {
        gameLink.value = comingJson.TwoPlayerGame;
        document.getElementById("gameFrame").src = `${gameLink.value
          }?appointmentId=${mqttTopic.value}&role=${user.role
          }&dktdatetime=${Date.now()}`;
        startGame.value = true;
      } else if (message.includes("openBoard")) {
        gameLink.value = comingJson.openBoard;
        document.getElementById("gameFrame").src =
          "https://board.dilvekonusmaterapim.com/?whiteboardid=" +
          mqttTopic.value +
          "&dktdatetime=" +
          Date.now() +
          "&method=join";
        startGame.value = true;
      } else if (message.includes("startGame")) {
        startGame.value = true;
      } else if (message.includes("stopGame")) {
        startGame.value = false;
        document.getElementById("gameFrame").src = "";
        gameLink.value = "";
      } else if (message.includes("pauseGame")) {
        startGame.value = false;
      } else if (message.includes("remoteShareScreen")) {
        remoteShareScreen.value = !remoteShareScreen.value;
      } else if (message.includes("remoteVideoLocation")) {
        remoteVideoLocation.value = !remoteVideoLocation.value;
      } else if (message.includes("remoteScreenSize")) {
        remoteScreenSize.value = !remoteScreenSize.value;
      } else if (message.includes("closeQuestion")) {
        questions.value = [];
        showPatientModal.value = false;
      } else if (message.includes("giveStarForPatient")) {
        gameStars.value = gameStars.value + 1;
      } else if (message.includes("questionAnswer")) {
        questionAnswer.value = comingJson.questionAnswer;
        console.log(questionAnswer.value);
        if (questionAnswer.value === "true") {
          gameStars.value = gameStars.value + 1;
        }
        questions.value = [];
        showPatientModal.value = false;
      } else if (message.includes("SettingMessage")) {
        let comingGameWidth = comingJson.gameWidth + "%";
        let comingQuestionCardColor = comingJson.QuestionCardColor;
        document.documentElement.style.setProperty(
          "--main-width-percent",
          comingGameWidth
        );
        document.documentElement.style.setProperty(
          "--question-background-color",
          comingQuestionCardColor
        );
      }
    } else if (user.role == "doctor") {
      if (message.includes("closeQuestion")) {
        questions.value = [];
      } else if (message.includes("questionAnswer")) {
        questions.value = [];
      } else if (message.includes("stopGame")) {
        startBoard.value = false;
        document.getElementById("gameFrame").src = "";
        gameLink.value = "";
      } else if (message.includes("pauseGame")) {
        startBoard.value = false;
      } else if (message.includes("TwoPlayerGame")) {
        startBoard.value = true;

        gameLink.value = comingJson.TwoPlayerGame;
        document.getElementById("gameFrame").src = `${gameLink.value
          }?appointmentId=${mqttTopic.value
          }&role=therapist&dktdatetime=${Date.now()}`;
      } else if (message.includes("SettingMessage")) {
        let comingGameWidth = comingJson.gameWidth + "%";
        let comingQuestionCardColor = comingJson.QuestionCardColor;
        document.documentElement.style.setProperty(
          "--main-width-percent",
          comingGameWidth
        );
        document.documentElement.style.setProperty(
          "--question-background-color",
          comingQuestionCardColor
        );
      }
    }
  };

  return {
    doSubscribe,
    createConnection,
    doPublish,
    publish,
    startGame,
    startBoard,
    showPatientModal,
    questions,
    gameLink,
    questionAnswer,
    microphone,
    camera,
    screenSize,
    shareScreen,
    videoLocation,
    remoteShareScreen,
    remoteVideoLocation,
    remoteScreenSize,
    gameStars,
  };
}
